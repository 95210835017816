import { default as _91_46_46_46slug_93mmV1R5yvcWMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue?macro=true";
import { default as _404gtxT6SjbdfMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue?macro=true";
import { default as change_45password_45_91uuid_93BySs6d3Qj2Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue?macro=true";
import { default as forgot_45passwordKYGUDJs1OFMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue?macro=true";
import { default as helptYySVaj4nBMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue?macro=true";
import { default as indexPutEfwV1mAMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue?macro=true";
import { default as loginJBpwVWaaLXMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue?macro=true";
import { default as not_45authorizedvVOpP8qmvOMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue?macro=true";
import { default as instruments6yrgAebHmTMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue?macro=true";
import { default as packspxYaY8shVxMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue?macro=true";
import { default as tags70oJj5eYcdMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue?macro=true";
import { default as users5rhItvWlYCMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue?macro=true";
import { default as videosM78bQh8SuJMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue?macro=true";
import { default as _91id_93JjhDU3ORU8Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue?macro=true";
import { default as homeTmZgvdEfZNMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue?macro=true";
import { default as _91id_93zqAhqyprgBMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue?macro=true";
import { default as listLIP5i6Bfv9Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue?macro=true";
import { default as _91id_93iZsTIiHIAFMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue?macro=true";
import { default as list2wjWQ4EwpvMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue?macro=true";
import { default as searchaqAAxiVVz2Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue?macro=true";
import { default as indexomRhFzPFN6Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue?macro=true";
import { default as cart3PskSL664EMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue?macro=true";
import { default as checkouth0PgRaraWzMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue?macro=true";
import { default as payment_45returnjlouFo7AsiMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue?macro=true";
import { default as payment1QP1sUpH0hMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue?macro=true";
import { default as _91tab_93HRGdrLOXRpMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue?macro=true";
import { default as favoritesc5euncClIIMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue?macro=true";
import { default as learningsumXVf8yNFMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue?macro=true";
import { default as purchasesEUGqJfLcLzMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue?macro=true";
import { default as salesVSmrRVgV6lMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue?macro=true";
import { default as email_45_91uuid_93l01HKffsRDMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue?macro=true";
import { default as user_45_91uuid_93z6ZQyEg8ElMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue?macro=true";
import { default as register5sOI5kiRQ7Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue?macro=true";
import { default as about58rUQ4vdfQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue?macro=true";
import { default as _91id_93vhPIngJ6yxMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue?macro=true";
import { default as _91id_93EiajxDu6tOMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue?macro=true";
import { default as _91id_93RiKLkEAjIlMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue?macro=true";
import { default as searchLs85qz9o2dMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue?macro=true";
import { default as contactAqRwhiXRd4Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue?macro=true";
import { default as desktop_45downloadw9uClvJMr4Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue?macro=true";
import { default as cartWH6JirjTXkMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue?macro=true";
import { default as _91id_93EWND5lIDqvMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue?macro=true";
import { default as indexJy747hNRQoMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue?macro=true";
import { default as content_45navigationUEvuxzF2HwMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-navigation.vue?macro=true";
import { default as content_45query_45alln95EAVQM6gMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-all.vue?macro=true";
import { default as content_45query_45playgroundTC1TtZf6vZMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-playground.vue?macro=true";
import { default as content_45searchcr9qBn3r0hMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-search.vue?macro=true";
import { default as component_45stub1eOqwJg13iMeta } from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass_3qcp527di4huv4x77bm43sriqm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1eOqwJg13i } from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass_3qcp527di4huv4x77bm43sriqm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93mmV1R5yvcWMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93mmV1R5yvcWMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "404___en",
    path: "/404",
    meta: _404gtxT6SjbdfMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404",
    meta: _404gtxT6SjbdfMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "change-password-uuid___en",
    path: "/change-password-:uuid()",
    meta: change_45password_45_91uuid_93BySs6d3Qj2Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "change-password-uuid___fr",
    path: "/fr/change-password-:uuid()",
    meta: change_45password_45_91uuid_93BySs6d3Qj2Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45passwordKYGUDJs1OFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordKYGUDJs1OFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "help___en",
    path: "/help",
    meta: helptYySVaj4nBMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "help___fr",
    path: "/fr/help",
    meta: helptYySVaj4nBMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexPutEfwV1mAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexPutEfwV1mAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginJBpwVWaaLXMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginJBpwVWaaLXMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "not-authorized___en",
    path: "/not-authorized",
    meta: not_45authorizedvVOpP8qmvOMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "not-authorized___fr",
    path: "/fr/not-authorized",
    meta: not_45authorizedvVOpP8qmvOMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "private-admin-instruments___en",
    path: "/private/admin/instruments",
    meta: instruments6yrgAebHmTMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-admin-instruments___fr",
    path: "/fr/private/admin/instruments",
    meta: instruments6yrgAebHmTMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-admin-packs___en",
    path: "/private/admin/packs",
    meta: packspxYaY8shVxMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-packs___fr",
    path: "/fr/private/admin/packs",
    meta: packspxYaY8shVxMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-tags___en",
    path: "/private/admin/tags",
    meta: tags70oJj5eYcdMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-admin-tags___fr",
    path: "/fr/private/admin/tags",
    meta: tags70oJj5eYcdMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-admin-users___en",
    path: "/private/admin/users",
    meta: users5rhItvWlYCMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "private-admin-users___fr",
    path: "/fr/private/admin/users",
    meta: users5rhItvWlYCMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "private-admin-videos___en",
    path: "/private/admin/videos",
    meta: videosM78bQh8SuJMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-admin-videos___fr",
    path: "/fr/private/admin/videos",
    meta: videosM78bQh8SuJMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-catalog-creators-id___en",
    path: "/private/catalog/creators/:id()",
    meta: _91id_93JjhDU3ORU8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "private-catalog-creators-id___fr",
    path: "/fr/private/catalog/creators/:id()",
    meta: _91id_93JjhDU3ORU8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "private-catalog-home___en",
    path: "/private/catalog/home",
    meta: homeTmZgvdEfZNMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-catalog-home___fr",
    path: "/fr/private/catalog/home",
    meta: homeTmZgvdEfZNMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-catalog-instruments-id___en",
    path: "/private/catalog/instruments/:id()",
    meta: _91id_93zqAhqyprgBMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-id___fr",
    path: "/fr/private/catalog/instruments/:id()",
    meta: _91id_93zqAhqyprgBMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-list___en",
    path: "/private/catalog/instruments/list",
    meta: listLIP5i6Bfv9Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-catalog-instruments-list___fr",
    path: "/fr/private/catalog/instruments/list",
    meta: listLIP5i6Bfv9Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-catalog-packs-id___en",
    path: "/private/catalog/packs/:id()",
    meta: _91id_93iZsTIiHIAFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-id___fr",
    path: "/fr/private/catalog/packs/:id()",
    meta: _91id_93iZsTIiHIAFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-list___en",
    path: "/private/catalog/packs/list",
    meta: list2wjWQ4EwpvMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-catalog-packs-list___fr",
    path: "/fr/private/catalog/packs/list",
    meta: list2wjWQ4EwpvMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-catalog-search___en",
    path: "/private/catalog/search",
    meta: searchaqAAxiVVz2Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private-catalog-search___fr",
    path: "/fr/private/catalog/search",
    meta: searchaqAAxiVVz2Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private___en",
    path: "/private",
    meta: indexomRhFzPFN6Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "private___fr",
    path: "/fr/private",
    meta: indexomRhFzPFN6Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "private-shop-cart___en",
    path: "/private/shop/cart",
    meta: cart3PskSL664EMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-shop-cart___fr",
    path: "/fr/private/shop/cart",
    meta: cart3PskSL664EMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-shop-checkout___en",
    path: "/private/shop/checkout",
    meta: checkouth0PgRaraWzMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-shop-checkout___fr",
    path: "/fr/private/shop/checkout",
    meta: checkouth0PgRaraWzMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-shop-payment-return___en",
    path: "/private/shop/payment-return",
    meta: payment_45returnjlouFo7AsiMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-shop-payment-return___fr",
    path: "/fr/private/shop/payment-return",
    meta: payment_45returnjlouFo7AsiMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-shop-payment___en",
    path: "/private/shop/payment",
    meta: payment1QP1sUpH0hMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "private-shop-payment___fr",
    path: "/fr/private/shop/payment",
    meta: payment1QP1sUpH0hMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "private-user-account-settings-tab___en",
    path: "/private/user/account-settings/:tab()",
    meta: _91tab_93HRGdrLOXRpMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: "private-user-account-settings-tab___fr",
    path: "/fr/private/user/account-settings/:tab()",
    meta: _91tab_93HRGdrLOXRpMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: "private-user-favorites___en",
    path: "/private/user/favorites",
    meta: favoritesc5euncClIIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-favorites___fr",
    path: "/fr/private/user/favorites",
    meta: favoritesc5euncClIIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-learning___en",
    path: "/private/user/learning",
    meta: learningsumXVf8yNFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "private-user-learning___fr",
    path: "/fr/private/user/learning",
    meta: learningsumXVf8yNFMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "private-user-purchases___en",
    path: "/private/user/purchases",
    meta: purchasesEUGqJfLcLzMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "private-user-purchases___fr",
    path: "/fr/private/user/purchases",
    meta: purchasesEUGqJfLcLzMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "private-user-sales___en",
    path: "/private/user/sales",
    meta: salesVSmrRVgV6lMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-user-sales___fr",
    path: "/fr/private/user/sales",
    meta: salesVSmrRVgV6lMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-verify-email-uuid___en",
    path: "/private/verify/email-:uuid()",
    meta: email_45_91uuid_93l01HKffsRDMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "private-verify-email-uuid___fr",
    path: "/fr/private/verify/email-:uuid()",
    meta: email_45_91uuid_93l01HKffsRDMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "private-verify-user-uuid___en",
    path: "/private/verify/user-:uuid()",
    meta: user_45_91uuid_93z6ZQyEg8ElMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "private-verify-user-uuid___fr",
    path: "/fr/private/verify/user-:uuid()",
    meta: user_45_91uuid_93z6ZQyEg8ElMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "register___en",
    path: "/register",
    meta: register5sOI5kiRQ7Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: register5sOI5kiRQ7Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "site-about___en",
    path: "/site/about",
    meta: about58rUQ4vdfQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-about___fr",
    path: "/fr/site/about",
    meta: about58rUQ4vdfQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-catalog-creators-id___en",
    path: "/site/catalog/creators/:id()",
    meta: _91id_93vhPIngJ6yxMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-creators-id___fr",
    path: "/fr/site/catalog/creators/:id()",
    meta: _91id_93vhPIngJ6yxMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___en",
    path: "/site/catalog/instruments/:id()",
    meta: _91id_93EiajxDu6tOMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___fr",
    path: "/fr/site/catalog/instruments/:id()",
    meta: _91id_93EiajxDu6tOMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "site-catalog-packs-id___en",
    path: "/site/catalog/packs/:id()",
    meta: _91id_93RiKLkEAjIlMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "site-catalog-packs-id___fr",
    path: "/fr/site/catalog/packs/:id()",
    meta: _91id_93RiKLkEAjIlMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "site-catalog-search___en",
    path: "/site/catalog/search",
    meta: searchLs85qz9o2dMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "site-catalog-search___fr",
    path: "/fr/site/catalog/search",
    meta: searchLs85qz9o2dMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "site-contact___en",
    path: "/site/contact",
    meta: contactAqRwhiXRd4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "site-contact___fr",
    path: "/fr/site/contact",
    meta: contactAqRwhiXRd4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "site-desktop-download___en",
    path: "/site/desktop-download",
    meta: desktop_45downloadw9uClvJMr4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "site-desktop-download___fr",
    path: "/fr/site/desktop-download",
    meta: desktop_45downloadw9uClvJMr4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "site-shop-cart___en",
    path: "/site/shop/cart",
    meta: cartWH6JirjTXkMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "site-shop-cart___fr",
    path: "/fr/site/shop/cart",
    meta: cartWH6JirjTXkMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "site-youtubers-channels-id___en",
    path: "/site/youtubers/channels/:id()",
    meta: _91id_93EWND5lIDqvMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "site-youtubers-channels-id___fr",
    path: "/fr/site/youtubers/channels/:id()",
    meta: _91id_93EWND5lIDqvMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "site-youtubers___en",
    path: "/site/youtubers",
    meta: indexJy747hNRQoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "site-youtubers___fr",
    path: "/fr/site/youtubers",
    meta: indexJy747hNRQoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "test-content-navigation___en",
    path: "/test/content-navigation",
    meta: content_45navigationUEvuxzF2HwMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-navigation.vue")
  },
  {
    name: "test-content-navigation___fr",
    path: "/fr/test/content-navigation",
    meta: content_45navigationUEvuxzF2HwMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-navigation.vue")
  },
  {
    name: "test-content-query-all___en",
    path: "/test/content-query-all",
    meta: content_45query_45alln95EAVQM6gMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-all.vue")
  },
  {
    name: "test-content-query-all___fr",
    path: "/fr/test/content-query-all",
    meta: content_45query_45alln95EAVQM6gMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-all.vue")
  },
  {
    name: "test-content-query-playground___en",
    path: "/test/content-query-playground",
    meta: content_45query_45playgroundTC1TtZf6vZMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-playground.vue")
  },
  {
    name: "test-content-query-playground___fr",
    path: "/fr/test/content-query-playground",
    meta: content_45query_45playgroundTC1TtZf6vZMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-query-playground.vue")
  },
  {
    name: "test-content-search___en",
    path: "/test/content-search",
    meta: content_45searchcr9qBn3r0hMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-search.vue")
  },
  {
    name: "test-content-search___fr",
    path: "/fr/test/content-search",
    meta: content_45searchcr9qBn3r0hMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/test/content-search.vue")
  },
  {
    name: component_45stub1eOqwJg13iMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub1eOqwJg13i
  },
  {
    name: component_45stub1eOqwJg13iMeta?.name,
    path: "/en-pages-sitemap.xml",
    component: component_45stub1eOqwJg13i
  },
  {
    name: component_45stub1eOqwJg13iMeta?.name,
    path: "/fr-pages-sitemap.xml",
    component: component_45stub1eOqwJg13i
  },
  {
    name: component_45stub1eOqwJg13iMeta?.name,
    path: "/en-catalog-sitemap.xml",
    component: component_45stub1eOqwJg13i
  },
  {
    name: component_45stub1eOqwJg13iMeta?.name,
    path: "/en-channels-sitemap.xml",
    component: component_45stub1eOqwJg13i
  }
]