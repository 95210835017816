import validate from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass_3qcp527di4huv4x77bm43sriqm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45auth_45global from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/middleware/1.auth.global.ts";
import _2_45i18n_45global from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/middleware/2.i18n.global.ts";
import manifest_45route_45rule from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass_3qcp527di4huv4x77bm43sriqm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45auth_45global,
  _2_45i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "load-search-index": () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/middleware/load-search-index.ts")
}